import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { StaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import { faGithubAlt, faLinkedin, faFacebook, faInstagram, faCodepen } from '@fortawesome/free-brands-svg-icons'
import { faCopyright } from '@fortawesome/free-solid-svg-icons'

import styles from './footer.module.css'

export default function Footer() {
    return (
        <StaticQuery
          query={graphql`
            query FooterQuery {
              allContentfulPerson(
                filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
              ) {
                edges {
                  node {
                   facebook
                   github
                   codepen
                   linkedin
                   instagram
                  }
                }
              }
            }
          `}
          render={data => (
                    <footer>
                    <hr></hr>
                    <ul className={styles.footerSocialLinks}>
                        <li className={styles.footerSocialLinksItemLeft}>
                            <FontAwesomeIcon icon={faCopyright} /> Anastasia Isaeva
                        </li>
                        <li className={styles.footerSocialLinksItem}>
                            <a href={data.allContentfulPerson.edges[0].node.github} target="_blank"><FontAwesomeIcon icon={faGithubAlt} /></a>
                        </li>
                        <li className={styles.footerSocialLinksItem}>
                            <a href={data.allContentfulPerson.edges[0].node.codepen} target="_blank"><FontAwesomeIcon icon={faCodepen} /></a>
                        </li>
                        <li className={styles.footerSocialLinksItem}>
                            <a href={data.allContentfulPerson.edges[0].node.linkedin} target="_blank"><FontAwesomeIcon icon={faLinkedin} /></a>
                        </li>
                        <li className={styles.footerSocialLinksItem}>
                            <a href={data.allContentfulPerson.edges[0].node.facebook} target="_blank"><FontAwesomeIcon icon={faFacebook} /></a>
                        </li>
                        <li className={styles.footerSocialLinksItem}>
                            <a href={data.allContentfulPerson.edges[0].node.instagram} target="_blank"><FontAwesomeIcon icon={faInstagram} /></a>
                        </li>
                    </ul>
                </footer>
          )}
          />
    )
}
